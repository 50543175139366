@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap');

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

#root {
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.full-width-container {
    width: 100%;
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
}

.inner-container {
    width: 100%; /* Ensure it stretches to fill its parent */
    height: 100%;
}

.content-area {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    height: calc(100% - 64px); /* Adjust for header height */
}

.left-panel {
    flex: 0 0 15vw;
    /* flex: 1 0 15%; */
    /* max-width: 15%; */
    background-color: #ffffff;
    height: 100vh;
    border-right: 2px solid #e5e7eb;
}

.right-panel {
    width: 300px;
    background-color: #f9fafb;
    overflow-y: auto;
    transition: width 0.3s ease-in-out;
    height: 100%;
}

@media (max-width: 768px) {
    .right-panel {
        width: 100%;
        position: fixed;
        top: 64px; /* Adjust for header height */
        right: 0;
        bottom: 0;
        z-index: 1000;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
    }

    .right-panel.open {
        transform: translateX(0);
    }
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #ffffff;
}

.footer {
    width: 100%;
}

.text-white {
    color: white;
    font-size: 14px;
}

.text-blue {
    color: #1f477d;
}

.text-black {
    color: black;
}

.app-container {
    display: flex;
    margin-top: 75px;
}

.top-header {
    color: #1f477d;
}

.top-header-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #e5e7eb;
}

.header-top-right {
    display: flex;
    align-items: center; /* Vertically center the logout button */
    justify-content: flex-end; /* Align the logout button to the right */
    padding-right: 2vw; /* Adjust as needed for spacing from the right edge */
}

.header-top {
    display: flex;
    align-items: center; /* Vertically center */
    justify-content: flex-start; /* Horizontally align to the left */
    padding-left: 2vw; /* Adjust as needed for spacing from the left edge */
}

.header-top-right {
    display: flex;
    align-items: center; /* Vertically center the logout button */
    justify-content: flex-end; /* Align the logout button to the right */
    padding-right: 2vw; /* Adjust as needed for spacing from the right edge */
}

.main-panel {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    transition: margin-left 0.3s ease-in-out;
    height: 100%;
}

.main-panel-header {
    display: flex;
    padding-top: 10px;
    flex-direction: row; /* Stack them vertically */
    align-items: flex-start; /* Align items to the left */
    justify-content: space-between; /* Align items to the top */
    padding-left: 10px 30px 10px 30px; /* Adjust padding as needed */
}

.main-panel-content {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    align-items: flex-start;
    /* min-width: 90vw; */
    min-width: 80vw;

    box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.secondary-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -20px;
}

.header-container,
.datagrid {
    width: 90%; /* Set the width to 90% of the screen width */
    margin: 0 auto; /* Center the divs horizontally */
    display: flex; /* Use flexbox to align items */
    flex-direction: column; /* Stack children vertically */
}

.secondary-text {
    text-align: left;
    font-size: 16px;
}

.new-document-button {
    cursor: pointer; /* Optional: Changes the cursor on hover */
    background-color: #1f477d;
    border-radius: 5px;
    padding: 0px 10px 0px 10px;
}

.unfilled-button {
    cursor: pointer; /* Optional: Changes the cursor on hover */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
    margin-top: 2vh;
}

.modal-background {
    position: fixed; /* Use fixed or absolute positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the modal */
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures the modal is above other content */
    min-width: 50%;
    min-height: 50%;
}

.datagrid {
    align-self: center;
    max-height: 90vh;
    padding: 10px;
    width: 90%;
}

nav {
    display: flex;
    flex-direction: column; /* Stack links vertically */
}

nav a {
    color: black; /* Default color for links */
    text-decoration: none; /* Optional: Removes underline from links */
    padding: 8px 0; /* Optional: Adds some padding for better spacing */
}

nav a:hover {
    color: grey; /* Color on hover */
}

.nav-logo {
    width: 20px;
    height: 20px;
    margin: 2px;
    justify-self: center;
    align-self: center;
    margin-right: 5px;
}

nav a {
    display: flex;
    align-items: center; /* This ensures vertical centering */
    text-decoration: none; /* Optional: removes underline from links */
    color: inherit;
    padding-left: 2vw;
    margin: 2px 10px 2px 0px;
}

.active {
    background-color: #f9fafb; /* Active link color */
    border-radius: 10px;
}

.folder-box {
    display: flex;
    flex-direction: column;
    padding: 10px; /* Adds padding inside the folder-box */
    /* border: 1px solid grey; */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adjusted for a lighter shadow */
    min-width: 90%;
    overflow: scroll;
    max-height: 80vh;
}

.folder-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Centers items vertically */
    padding: 0 10px; /* Adds padding on the left and right */
}

.folder-info {
    padding: 10px; /* Adds padding around the folder description */
    align-items: flex-start;
}

.add-document-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
    border-radius: 5px;
    padding: 4px 10px 4px 10px;
}

.add-document-button p {
    margin: 0;
    padding: 2px;
}

.document-card {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid grey;
    padding: 10px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    font-size: 16px;
}

.button-list {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* This will make the buttons stretch to the same width */
    gap: 10px; /* Adjust the gap between buttons as needed */
    padding: 20px;
    max-height: 30vw;
    overflow-y: scroll;
}

.document-type-button {
    width: 20vw; /* Makes each button fill the container width */
    height: 10vw; /* Example height, adjust as needed */
    background-color: white;
}

.close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #1f477d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Adds some space between the input fields */
}

/* Optional: Center the entire form or input area */
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
}

.input-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-bottom: 20px; /* Adds some space between the input fields */
    /* cursor: pointer; */

    padding: 10px;
}

.dashed-border {
    border: 2px dashed #1f477d;
}

.webviewContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.MyComponent {
    display: flex;
    flex-grow: 100;
    height: 100%;
}

.webviewer {
    display: flex;
    flex-grow: 100;
    margin-left: -20px;
    height: 100%;
}

.tabs {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background: #f0f0f0;
    border-bottom: 1px solid #ddd;
}

.tabs button {
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    background: none;
    outline: none;
    font-size: 16px;
}

.tabs button.active {
    border-bottom: 2px solid #1f477d;
    font-weight: bold;
}

.tab-content {
    padding: 20px;
}

.chat-box {
    display: flex;
    height: 500px;
    flex-direction: column;
}

.modal-input {
    width: 20vw;
    padding-block: 10px;
    padding-inline: 0px;
    font-size: 16px;
}

.modal-submit {
    margin: 20px 0px 10px 0px;
    background-color: #1f477d;
    border-radius: 5px;
    min-width: 10vw;
}

.modal-button-container {
    display: flex;
    justify-content: center; /* Aligns button to the right */
    width: 90%; /* Ensures the container spans the full width of its parent */
}

.generate-modal-submit {
    width: 100px; /* Example width */
    height: 40px; /* Example height */
    background-color: #1f477d; /* Example background color */
    color: white; /* Text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}

.grey-button {
    background-color: #d1d5db; /* Grey background color */
}

.generate-modal-submit p {
    margin: 0; /* Remove margin from <p> to align text properly */
}

.document-type-button {
    background-color: white; /* Default background */
    color: black; /* Text color */
    border: 1px solid #1f477d; /* Border color */
    padding: 10px 20px; /* Padding */
    margin: 5px; /* Margin */
    cursor: pointer; /* Cursor on hover */
}

.document-type-button.selected {
    background-color: #d3d3d3; /* Grey for selected */
}

multiple-file-upload-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Add other styling as needed */
}

.file-info {
    display: flex;
    align-items: center;
}

.file-name {
    margin-left: 10px; /* Adjust spacing between image and file name */
    /* Add other styling as needed */
}

.multiple-file-upload-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 60vw;
}

.file-name {
    text-align: left;
    margin-right: auto; /* Ensures the name is left-aligned and the button stays to the right */
}

.remove-file-btn {
    padding: 5px 10px;
    background-color: #f44336; /* Red color for remove button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.select-file-btn {
    padding: 5px 10px;
    background-color: #1f477d; /* Red color for remove button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.view-file-btn {
    padding: 5px 10px;
    background-color: #d1d5db; /* Red color for remove button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.input-wrapper input,
.input-wrapper textarea {
    width: 100%; /* Makes inputs take the full width of their parent container */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
    margin-bottom: 20px; /* Adds space between each input */
}

.input-wrapper textarea {
    height: 200px; /* Makes the textarea considerably longer */
}

.input-wrapper {
    width: 90%; /* Example: Adjust according to your modal's width */
    margin: auto; /* Centers the wrapper if the modal is wider */
}

.source-card {
    cursor: pointer;
    display: flex;
    align-items: start;
    flex-direction: column;
    margin: 2px;
    background-color: #e5e7eb;
}

.general-select-btn {
    padding: 5px 10px;
    background-color: #d1d5db; /* Red color for remove button */
    margin: 10px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.selected-file-card {
    border: 2px solid #1f477d; /* Adjust the styling as needed */
}

.cs-message__content {
    background-color: inherit !important;
    padding: 10px; /* Optional: Add padding for better spacing */
}

.message-box-dark {
    background-color: #ffffff;
    /* border-radius:10px; */
    border-top: 1px solid #e5e7eb; /* Line at the top of each message */
    border-bottom: 1px solid #e5e7eb; /* Line at the bottom of each message */
    padding: 10px;
}

.message-box-light {
    /* background-color: #1DC989; */
    /* border-radius:10px; */
    border-top: 1px solid #e5e7eb; /* Line at the top of each message */
    border-bottom: 1px solid #e5e7eb; /* Line at the bottom of each message */
    padding: 10px;
}

.cs-main-container {
    border: none !important;
    background-color: inherit !important;
}

.cs-chat-container {
    border: none !important;
    background-color: inherit !important;
}

/* .cs-button {
  color:#1DC989 !important
} */

.cs-button:disabled {
    color: #ffffff !important;
}

.cs-message-input {
    background-color: inherit !important;
}

.cs-message-input__content-editor {
    background-color: white !important;
    font-family: inherit !important;
    border: 1px solid #e5e7eb; /* Added border for outlined box */
}

.cs-message-list {
    background-color: inherit !important;
}

.cs-message-input__content-editor-wrapper {
    background-color: white !important;
}

.centered {
    display: flex;
    justify-content: center;
}

.cs-typing-indicator__dot {
    background-color: #1f477d !important;
}

.cs-message-list .cs-typing-indicator {
    position: relative !important;
}

.cs-message-input__tools:last-child .cs-button:last-child {
    color: #1f477d !important;
}

.ps__thumb-y {
    background-color: #1f477d !important;
}

.green-button {
    width: 90%;
    height: 60%;
    background-color: #e8f5e9;
    border: 0px solid #00ff00;
    border-radius: 20px;
    color: #047857;
}

.main-button {
    max-width: 1000px;
    height: 60%;
    background-color: #1f477d;
    border: 0px solid #1f477d;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: opacity 0.2s ease; /* Smooth transition for opacity */
}

.main-button:hover {
    opacity: 0.85; /* Slightly reduce opacity on hover */
    cursor: pointer;
}

.unfilled-button {
    max-width: 1000px;
    height: 60%;
    border: 0px solid #fee2e2;
    border-radius: 5px;
    color: #ef4444;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.unfilled-not-neg-button {
    max-width: 1000px;
    height: 60%;
    border: 0px solid #fee2e2;
    border-radius: 5px;
    color: #1f477d;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-bar {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Pushes the button to the right */
    width: 100%; /* Ensures the bar takes the full width */
    height: 50px;
}

.upload-container {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    width: 75vw;
    position: relative;
}

.input-container {
    display: flex;
    margin-bottom: 16px;
}

.file-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.tiles-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-content: center; /* Center the grid items horizontally */
}

.template-tab-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Adjusts components to the bottom */
}

.template-datagrid {
    align-self: center;
    max-height: 75vh;
    width: 100%;
}

.tile {
    width: 100%;
    height: 150px;
    border: 2px solid #e5e7eb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: bold;
    background: #ffffff;
}

.upload-area {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    cursor: pointer;
    width: 90%;
    margin: 20px auto;
    transition: border-color 0.3s;
}

.upload-area:hover {
    border-color: #888;
}

.tile-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 20px;
    height: 100%;
    background-color: #ffffff;
    border: 0px solid #e5e7eb;
}

.file-info {
    padding: 5px;
    border-radius: 10px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e5e7eb;
}

.file-info button {
    /* background-color: #f44336; */
    color: #000000;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.toggle-button {
    padding: 5px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}

.toggle-button.on {
    background-color: #ecfdf5;
    color: #047857;
    border: 1px solid #d1fae5;
}

.toggle-button.off {
    background-color: #fee2e2;
    color: #ef4444;
    border: 1px solid #fee2e2;
}

.toggle-slider {
    position: relative;
    width: 60px;
    height: 34px;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

input[type='checkbox'] {
    display: none;
}

input[type='checkbox']:checked + .slider {
    background-color: #047857;
}

input[type='checkbox']:checked + .slider:before {
    transform: translateX(26px);
}

.delete-button {
    background: none;
    border: none;
    color: #ef4444;
    cursor: pointer;
    font-size: 24px;
    margin-left: 10px;
}

.delete-button i {
    pointer-events: none;
}

.side-by-side-container {
    display: flex;
    align-items: center; /* Align items vertically centered */
    gap: 10px; /* Adjust the gap between items */
}

.header-within-container {
    margin-top: 10px;
    margin-bottom: -8px;
}

.automation-item {
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.sidebar {
    width: 400px;
    height: 100vw;
    margin-top: -20px;
    margin-left: 20px;
    border-left: 2px solid #e5e7eb;
    background-color: #f9fafb;
    transition: transform 0.3s ease-in-out;
}

.sidebar-open {
    transform: translateX(0);
}

.sidebar-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 2px solid #e5e7eb;
}

.icon-text-container {
    display: flex;
    align-items: center;
}

.sidebar-icon {
    margin-right: 10px;
}

.sidebar-button {
    background-color: #1f477d;
    color: white;
    border-radius: 5px;
    padding: 10px;
}

.status-button {
    padding: 5px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.status-button.on {
    background-color: #ecfdf5;
    color: #047857;
    border: 1px solid #d1fae5;
}

.status-button.off {
    background-color: #fffbeb;
    color: #b45309;
    border: 1px solid #fef3c7;
}

.status-button.maybe {
    background-color: #fee2e2;
    color: #ef4444;
    border: 1px solid #fee2e2;
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    padding: 40px;
    text-align: center;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #1f477d; /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.document-type-container {
    display: flex;
    flex-wrap: wrap;
    max-height: 100%; /* Adjust the height as needed */
    overflow-y: auto;
    width: 80%; /* Ensure the container takes up the full width */
}

.document-type-button {
    flex: 0 1 calc(20% - 10px); /* Ensure each item takes up 20% of the width minus margin */
    box-sizing: border-box;
    margin: 5px; /* Add some margin for spacing */
    height: 100px; /* Ensure consistent height */
}

.checkmark-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: green;
}

.fixed-bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* Ensure it appears above other elements */
}

.message-input input::placeholder {
    text-align: left; /* Align placeholder text to the left */
}

.panel-button {
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.blue {
    background-color: #1f477d;
}
.MuiTabs-root {
    background-color: #ffffff;
    border-bottom: 1px solid #e5e7eb;
}

.MuiTab-root {
    text-transform: none;
    font-weight: 600;
}

.MuiDataGrid-root {
    border: none;
    background-color: #ffffff;
    height: 100%;
}
